import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";

import LoginView from "../views/LoginView.vue";
import Viphome from "../views/Viphome.vue";
//import Wxmin from "../views/Wxmin.vue";

import axios from "axios";
import store from "../store";
import peoplepage from "../views/webpage/peoplepage.vue";
import associationpage from "../views/webpage/associationpage.vue"
const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
    //component: () =>
    //import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },
  
  //------------------wxmin--------------------------------
  {
    path: "/wxmin/:uid/:pw",
    name: "wxmin",
    //component: Wxmin,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Wxmin.vue"),
  },
  {
    path: "/wx/wxminfo",
    name: "wx/wxminfo",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxminfo.vue"),
  },

  {
    path: "/wx/wxfinfo",
    name: "wx/wxfinfo",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxfinfo.vue"),
  },
  {
    path: "/wx/wxftalk/:userid/:name/:id/:type",
    name: "wx/wxftalk",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxftalk.vue"),
  },
  {
    path: "/wx/wxmteam",
    name: "wx/wxmteam",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxmteam.vue"),
  },
  {
    path: "/wx/wxmteamtalk/:fid/:id",
    name: "wx/wxmteamtalk",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxmteamtalk.vue"),
  },
  {
    path: "/wx/wxnewspage",
    name: "wx/wxnewspage",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxnewspage.vue"),
  },
  {
    path: "/wx/wxcommunitypage",
    name: "wx/wxcommunitypage",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxcommunitypage.vue"),
  },
  {
    path: "/wx/wxcommunitydetail/:newsid",
    name: "wx/wxcommunitydetail",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/wx/wxcommunitydetail.vue"
      ),
  },
  {
    path: "/wx/wxmfile",
    name: "wx/wxmfile",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxmfile.vue"),
  },
  {
    path: "/wx/wxsystemnotice",
    name: "wx/wxsystemnotice",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxsystemnotice.vue"),
  },
  {
    path: "/wx/wxcomment/:userida/:useridb/:readed",
    name: "wx/wxcomment",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxcomment.vue"),
  },
  {
    path: "/wx/wxmcomment",
    name: "wx/wxmcomment",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxmcomment.vue"),
  },
  {
    path: "/wx/wxmteacher",
    name: "wx/wxmteacher",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxmteacher.vue"),
  },
  {
    path: "/wx/wxexperts",
    name: "wx/wxexperts",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wx/wxexperts.vue"),
  },
  {
    path: "/wx/wxexpert/:userid/:type/:tdtype",
    name: "wx/wxexpert",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/wx/wxexpert.vue"
      ),
  },
  {
    path: "/wx/wxMdata",
    name: "wx/wxMdata",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/wx/wxMdata.vue"
      ),
  },
  {
    path: "/wx/wxgame",
    name: "wx/wxgame",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/wx/wxgame.vue"
      ),
      redirect: "/wx/wxPpk",
        children: [
         {
            path: "/wx/wxltcomp",
            name: "wx/wxltcomp",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/wx/wxltcomp.vue"
              ),
          },

          
          {
            path: "/wx/wxwebcomppage",
            name: "wx/wxwebcomppage",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/wx/wxwebcomppage.vue"
              ),
          },

          {
            path: "/wx/wxwebcomp",
            name: "wx/wxwebcomp",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/wx/wxwebcomp.vue"
              ),
          },

         
          
          {
            path: "/wx/wxPpk",
            name: "wx/wxPpk",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/wx/wxPpk.vue"
              ),
          },
          {
            path: "/wx/wxitcomppage",
            name: "wx/wxitcomppage",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/wx/wxltcomppage.vue"
              ),
          },
        ],
  },
  //--------------webmenu------------------------------------------------------------------
  {
    path: "/about",
    name: "about",
    component: AboutView,
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    redirect: "/webpage/homepage",
    children: [
      {
        path: "/webpage/newspage",
        name: "webpage/newspage",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/newspage.vue"
          ),
      },

      {
        path: "/webpage/peoplepage",
        name: "webpage/peoplepage",
        component: peoplepage,
        // component: () =>
        //   import(/* webpackChunkName: "about" */ "../views/webpage/peoplepage.vue"),
        redirect: "/webpage/people/Mdata",
        children: [
          {
            path: "/webpage/people/Mdata",
            name: "webpage/people/Mdata",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Mdata.vue"
              ),
          },
          {
            path: "/webpage/people/Mcomment",
            name: "webpage/people/Mcomment",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Mcomment.vue"
              ),
          },
          {
            path: "/webpage/people/Systemnotice",
            name: "webpage/people/Systemnotice",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Systemnotice.vue"
              ),
          },
          {
            path: "/webpage/people/ltcomp",
            name: "webpage/people/ltcomp",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/ltcomp.vue"
              ),
          },

          {
            path: "/webpage/people/Finfo",
            name: "webpage/people/Finfo",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Finfo.vue"
              ),
          },
          {
            path: "/webpage/people/Minfo",
            name: "webpage/people/Minfo",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Minfo.vue"
              ),
          },

          {
            path: "/webpage/people/webcomp",
            name: "webpage/people/webcomp",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/webcomp.vue"
              ),
          },

          {
            path: "/webpage/people/Mteam",
            name: "webpage/people/Mteam",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Mteam.vue"
              ),
          },
          {
            path: "/webpage/people/Mfile",
            name: "webpage/people/Mfile",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Mfile.vue"
              ),
          },
          {
            path: "/webpage/people/Mteacher",
            name: "webpage/people/Mteacher",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Mteacher.vue"
              ),
          },
          {
            path: "/webpage/people/Ppk",
            name: "webpage/people/Ppk",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Ppk.vue"
              ),
          },
          {
            path: "/webpage/people/Tanalysis",
            name: "webpage/people/Tanalysis",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/people/Tanalysis.vue"
              ),
          },
        ],
      },
      //--------association
      {
        path: "/webpage/associationpage",
        name: "webpage/associationpage",
        component: associationpage,
        // component: () =>
        //   import(/* webpackChunkName: "about" */ "../views/webpage/associationpage.vue",
        redirect: "/webpage/association/overview",
        children: [
          {
            path: "/webpage/association/overview",
            name: "webpage/association/overview",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/overview.vue"
              ),
          },
          {
            path: "/webpage/association/leaders",
            name: "webpage/association/leaders",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/leaders.vue"
              ),
          },
          {
            path: "/webpage/association/policys/:type",
            name: "webpage/association/policys",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/policys.vue"
              ),
          },

          {
            path: "/webpage/association/policy/:name",

            name: "webpage/association/policy",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/policy.vue"
              ),
          },

          {
            path: "/webpage/association/trends/:type",
            name: "webpage/association/trends",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/trends.vue"
              ),
          },

          {
            path: "/webpage/association/members/:type",
            name: "webpage/association/members",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/members.vue"
              ),
          },

          {
            path: "/webpage/association/member/:name",

            name: "webpage/association/member",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/member.vue"
              ),
          },

          {
            path: "/webpage/association/trend/:name",

            name: "webpage/association/trend",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/trend.vue"
              ),
          },

          {
            path: "/webpage/association/policy/:name",

            name: "webpage/association/policy",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/policy.vue"
              ),
          },
          {
            path: "/webpage/association/service",
            name: "webpage/association/service",

            component: () =>
              import(
          /* webpackChunkName: "about" */ "../views/webpage/association/service.vue"
              ),
          },
        ],
      },
      //---------------------------------
      {
        path: "/webpage/homepage",
        name: "webpage/homepage",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/homepage.vue"
          ),
      },
      {
        path: "/webpage/communitypage",
        name: "webpage/communitypage",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/communitypage.vue"
          ),
      },
      {
        path: "/webpage/communitydetail/:newsid",
        name: "webpage/communitydetail",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/communitydetail.vue"
          ),
      },
     
      {
        path: "/webpage/ltcomppage",
        name: "webpage/ltcomppage",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/ltcomppage.vue"
          ),
      },
      {
        path: "/webpage/webcomppage",
        name: "webpage/webcomppage",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/webcomppage.vue"
          ),
      },
      {
        path: "/webpage/trainhealthpage",
        name: "webpage/trainhealthpage",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/trainhealthpage.vue"
          ),
        redirect: "/webpage/trainhealth/couser",
        children: [
          {
            path: "/webpage/trainhealth/trainer",
            name: "webpage/trainhealth/trainer",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/trainer.vue"
              ),
          },
          {
            path: "/webpage/trainhealth/tdexpert/:userid/:type/:tdtype",
            name: "webpage/trainhealth/tdexpert",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/tdexpert.vue"
              ),
          },
          {
            path: "/webpage/trainhealth/tdexperts/:tdtype",
            name: "webpage/trainhealth/tdexperts",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/tdexperts.vue"
              ),
          },
          {
            path: "/webpage/trainhealth/commfile/:id/:type/:tdtype",
            name: "webpage/trainhealth/commfilt",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/commfile.vue"
              ),
          },
          {
            path: "/webpage/trainhealth/commfiles/:type/:tdtype",
            name: "webpage/trainhealth/commfiles",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/commfiles.vue"
              ),
          },
          {
            path: "/webpage/trainhealth/doctor",
            name: "webpage/trainhealth/doctor",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/doctor.vue"
              ),
          },

          {
            path: "/webpage/trainhealth/couser",
            name: "webpage/trainhealth/couser",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/couser.vue"
              ),
          },
          {
            path: "/webpage/trainhealth/sportsperscription",
            name: "webpage/trainhealth/sportsperscription",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/sportsperscription.vue"
              ),
          },

          {
            path: "/webpage/trainhealth/healthfood",
            name: "webpage/trainhealth/healthfood",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/healthfood.vue"
              ),
          },

          {
            path: "/webpage/trainhealth/healthconcept",
            name: "webpage/trainhealth/healthconcept",

            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/webpage/trainhealth/healthconcept.vue"
              ),
          },
        ],
      },

      {
        path: "/webpage/productpage",
        name: "webpage/productpage",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/productpage.vue"
          ),
      },
      {
        path: "/webpage/orderpage",
        name: "webpage/orderpage",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/orderpage.vue"
          ),
      },
      {
        path: "/webpage/wprogram",
        name: "webpage/wprogram",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/wprogram.vue"
          ),
      },
      {
        path: "/webpage/wprogramdetail/:pfid/:name/:starttime/:endtime",
        name: "webpage/wprogramdetail",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/wprogramdetail.vue"
          ),
      },
      {
        path: "/webpage/wprogramdetail2/:pfid/:name/:starttime/:endtime",
        name: "webpage/wprogramdetail2",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/wprogramdetail2.vue"
          ),
      },
      {
        path: "/webpage/wprogramdetail3/:pfid/:name/:starttime/:endtime",
        name: "webpage/wprogramdetail3",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/wprogramdetail3.vue"
          ),
      },
      {
        path: "/webpage/wprogramdetail4/:pfid/:name/:starttime/:endtime",
        name: "webpage/wprogramdetail4",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/wprogramdetail4.vue"
          ),
      },
      {
        path: "/webpage/wprogramdetail7/:pfid/:name/:starttime/:endtime",
        name: "webpage/wprogramdetail7",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/wprogramdetail7.vue"
          ),
      },
      {
        path: "/webpage/wprogramdetail8/:pfid/:name:starttime/:endtime",
        name: "webpage/wprogramdetail8",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/wprogramdetail8.vue"
          ),
      },
      {
        path: "/webpage/wprogramdetail10/:pfid/:name/:starttime/:endtime",
        name: "webpage/wprogramdetail10",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/webpage/wprogramdetail10.vue"
          ),
      },
    ],
  },
  //------------------vipmenu-------------------------------------------------
  {
    path: "/VIPhome",
    name: "VIPhome",
    component: Viphome,
    //component: () =>
    //  import( "../views/LoginView.vue")
    // redirect: "/VIP/department",
    children: [
      {
        path: "/VIP/subrange",
        name: "VIP/subrange",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/subrange.vue"),
      },
      {
        path: "/VIP/e-info",
        name: "VIP/e-info",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/e-info.vue"),
      },
      {
        path: "/VIP/b-couser",
        name: "VIP/b-couser",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/b-couser.vue"),
      },
      {
        path: "/VIP/progdictionary",
        name: "VIP/progdictionary",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/progdictionary.vue"),
      },
      {
        path: "/VIP/b-food",
        name: "VIP/b-food",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/b-food.vue"),
      },
      {
        path: "/VIP/b-sportsperscription",
        name: "VIP/b-sportsperscription",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/b-sportsperscription.vue"),
      },
      {
        path: "/VIP/b-healthconcept",
        name: "VIP/b-healthconcept",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/b-healthconcept.vue"),
      },
      {
        path: "/VIP/examinea",
        name: "VIP/examinea",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/examinea.vue"),
      },
      {
        path: "/VIP/examineb",
        name: "VIP/examineb",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/examineb.vue"),
      },
      {
        path: "/VIP/examinec",
        name: "VIP/examinec",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/examinec.vue"),
      },
      {
        path: "/VIP/examined",
        name: "VIP/examined",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/examined.vue"),
      },
      {
        path: "/VIP/exnews",
        name: "VIP/exnews",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/exnews.vue"),
      },
      {
        path: "/VIP/newsex",
        name: "VIP/newsex",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/newsex.vue"),
      },
      {
        path: "/VIP/newstr",
        name: "VIP/newstr",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/newstr.vue"),
      },
      {
        path: "/VIP/newsme",
        name: "VIP/newsme",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/newsme.vue"),
      },
      {
        path: "/VIP/newspl",
        name: "VIP/newspl",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/newspl.vue"),
      },
      {
        path: "/VIP/a-leader",
        name: "VIP/a-leader",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/a-leader.vue"),
      },
      {
        path: "/VIP/a-trends",
        name: "VIP/a-trends",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/a-trends.vue"),
      },
      {
        path: "/VIP/a-members",
        name: "VIP/a-members",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/a-members.vue"),
      },
      {
        path: "/VIP/police",
        name: "VIP/police",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/police.vue"),
      },
      {
        path: "/VIP/a-overview",
        name: "VIP/a-overview",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/a-overview.vue"),
      },
      {
        path: "/VIP/a-service",
        name: "VIP/a-service",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/a-service.vue"),
      },
      {
        path: "/VIP/d-pinfo",
        name: "VIP/d-pinfo",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/d-pinfo.vue"),
      },
      {
        path: "/VIP/analysis",
        name: "VIP/analysis",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/analysis.vue"),
      },
      {
        path: "/VIP/program",
        name: "VIP/program",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/program.vue"),
      },
      {
        path: "/VIP/programdetail/:pfid/:name",
        name: "VIP/programdetail",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail.vue"),
      },
      {
        path: "/VIP/programdetail1/:pfid/:name",
        name: "VIP/programdetail1",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail1.vue"),
      },
      {
        path: "/VIP/programdetail2/:pfid/:name",
        name: "VIP/programdetail2",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail2.vue"),
      },
      {
        path: "/VIP/programdetail3/:pfid/:name",
        name: "VIP/programdetail3",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail3.vue"),
      },
      {
        path: "/VIP/programdetail4/:pfid/:name",
        name: "VIP/programdetail4",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail4.vue"),
      },
      {
        path: "/VIP/programdetail5/:pfid/:name",
        name: "VIP/programdetail5",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail5.vue"),
      },
      {
        path: "/VIP/programdetail6:pfid/:name",
        name: "VIP/programdetail6",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail6.vue"),
      },
      {
        path: "/VIP/programdetail7/:pfid/:name",
        name: "VIP/programdetail7",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail7.vue"),
      },
      {
        path: "/VIP/programdetail8/:pfid/:name",
        name: "VIP/programdetail8",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail8.vue"),
      },
      {
        path: "/VIP/programdetail9/:pfid/:name",
        name: "VIP/programdetail9",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail9.vue"),
      },
      {
        path: "/VIP/programdetail10/:pfid/:name",
        name: "VIP/programdetail10",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/programdetail10.vue"),
      },
      {
        path: "/VIP/analysist",
        name: "VIP/analysist",
        //component: peoplepage,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/analysist.vue"),
      },
      {
        path: "/VIP/authorize",
        name: "VIP/authorize",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/authorize.vue"),
      },
      {
        path: "/VIP/competition",
        name: "VIP/competition",

        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/VIP/competition.vue"
          ),
      },
      {
        path: "/VIP/files",
        name: "VIP/files",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/files.vue"),
      },
      {
        path: "/VIP/expertfile",
        name: "VIP/expertfile",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/expertfile.vue"),
      },
      {
        path: "/VIP/alldata",
        name: "VIP/alldata",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/alldata.vue"),
      },
      {
        path: "/VIP/motiondata",
        name: "VIP/motiondata",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/motiondata.vue"),
      },
      {
        path: "/VIP/expertmotion",
        name: "VIP/expertmotion",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/expertmotion.vue"),
      },
      {
        path: "/VIP/expertfile",
        name: "VIP/expertfile",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/expertfile.vue"),
      },

      {
        path: "/VIP/newsinfo",
        name: "VIP/newsinfo",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/newsinfo.vue"),
      },

      {
        path: "/VIP/peopleset",
        name: "VIP/peopleset",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/peopleset.vue"),
      },
      {
        path: "/VIP/studentt",
        name: "VIP/studentt",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/studentt.vue"),
      },
      {
        path: "/VIP/studentd",
        name: "VIP/studentd",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VIP/studentd.vue"),
      },
      
    ],
  },
  //---------------------------------------------------------------------------
  {
    path: "/login",
    name: "login",
    component: LoginView,
    // component: () =>
    // import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let hasRoute = store.state.hasRoutes;

  let token = localStorage.getItem("token");

  if (to.path == "/about" || to.path=="/") {
    
    next({ path: "/about" });

  } else {
    if (to.path.startsWith("/webpage") || to.path.startsWith("/wx")) {
      next();
    }
    else {
      if (to.path == "/login") {
        next();
      }
      else {
        if (!token && to.path.startsWith("/VIP")) {
         console.log("aaaaa")
          next({ path: "/about" });
          
        }

        else {
          if (token && to.path.startsWith("/VIP")) {
            next();
          }
          else {
            if (token) {

              axios

                .get(store.state.imageurl + "/infomenu/nav", {
                  headers: {
                    token: localStorage.getItem("token"),
                    type: "admin",
                  },
                })
                .then((res) => {


                  // 拿到menuList
                  store.commit("setMenuList", res.data.data);


                  // 拿到用户权限
                  //	store.commit("setPermList", res.data.data.authoritys)



                  // 动态绑定路由

                  res.data.data.forEach(
                    (menu: {
                      children: {
                        component: string;
                        name: any;
                        path: any;
                        icon: any;
                        title: any;
                      }[];
                    }) => {
                      if (menu.children) {
                        menu.children.forEach(
                          (e: {
                            component: string;
                            name: any;
                            path: any;
                            icon: any;
                            title: any;
                          }) => {
                            // 转成路由


                            let route = menuToRoute(e);

                            // 吧路由添加到路由管理中
                            if (route) {
                              router.addRoute("home", route);

                            }
                          }
                        );
                      }
                    }
                  );

                  hasRoute = true;
                  store.commit("changeRouteStatus", hasRoute);
                });
              next();
            }
            else {
              if (!token) {
               
                next({ path: "/about" });
               
              }

            }


          }
        }
      }
    }
  }


  //next();
});

const menuToRoute = (menu: any) => {

  if (!menu.path) {
    return null;
  }

  let route = {
    path: menu.path,

    component: () => import("../views" + menu.path + ".vue"),
    meta: {
      icon: menu.icon,
      title: menu.title,
    },
  };

  return route;
};

export default router;
